import { makeAutoObservable } from 'mobx';
import { get, post } from '../utils/request';
import { Message } from '@arco-design/web-react';
class MainCategory {
  rawList = [];
  total = 0;
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  get list() {
    const list=this.rawList.map((o) => {
      return {
        ...o,
      };
    });
    return list
  }

  async create(formData) {
    //一级目录创建
    const result = await post('/api/inner/main/category/create', formData);
    if (result) {
      Message.success('操作成功');
    }
  }
  async update(formData) {
    const result = await post('/api/inner/main/category/update', formData);
    if (result) {
      Message.success('操作成功');
    }
  }
  async getList() {
    const result = await get('/api/inner/main/category/list', { limit: 600 });
    if (result) {
      this.rawList = result.list;
      this.total = result.total;
      this.loading = false;
      return this.list;
    }
    return false;
  }

  findMainCategory(id) {
    if (!id) return '未分类';
    return this.rawList
      .map((o) => {
        return {
          ...o,
        };
      })
      .find((a) => a._id == id).title;
  }
}
const mainCategory = new MainCategory();
mainCategory.getList();
export default mainCategory;

import { makeAutoObservable } from 'mobx';
import { get, post } from '@/utils/request';
import Cookies from 'js-cookie';
import { Message } from '@arco-design/web-react';
class Admin {
  _id = '';
  username = '';
  avatar =
    '//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/8361eeb82904210b4f55fab888fe8416.png~tplv-uwbnlip3yd-webp.webp';
  role = '';
  permissions = {
    admin: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  setUserInfo(userInfo) {
    this._id = userInfo._id;
    this.username = userInfo.username;
    this.role = userInfo.role;
    this.permissions = userInfo.permissions;
  }

  async fetchUserInfo() {
    const result = await get('/api/inner/admin/info');
    if (result && result._id) {
      this.setUserInfo(result);
      return result;
    }
    return false;
  }

  async login(params) {
    const result = await post('/api/inner/auth/login', params);
    if (result) {
      this.setUserInfo(result);
      Message.success('登录成功');
    } else {
      throw Error('登录失败');
    }
  }

  async logout() {
    const result = await post('/api/inner/auth/logout');
    if (result) {
      Message.success('退出登录');
      window.location.href = '/login';
    }
  }

  async create(formData) {
    const result = await post('/api/inner/admin/create', formData);
    if (result) {
      Message.success('操作成功');
    }
  }

  checkLogin() {
    return Cookies.get('isAdminLogin');
  }
}

export default new Admin();

import { makeAutoObservable } from 'mobx';
import { get, post } from '../utils/request';
import { Message } from '@arco-design/web-react';
class Category {
  rawList = [];
  total = 0;
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  get list() {
    return this.rawList.map((o) => {
      return {
        ...o,
      };
    });
  }

  async mainCategoryCreate(formData) {
    //一级目录创建
    const result = await post('/api/inner/main/category/create', formData);
    if (result) {
      Message.success('操作成功');
    }
  }
  async mainCategoryUpdate(formData) {
    const result = await post('/api/inner/main/category/update', formData);
    if (result) {
      Message.success('操作成功');
    }
  }
  async getMainCategoryList() {
    const result = await get('/api/inner/main/category/list', { limit: 600 });
    if (result) {
      this.rawList = result.list;
      this.total = result.total;
      this.loading = false;
      return this.list;
    }
    return false;
  }
  async create(formData) {
    const result = await post('/api/inner/category/create', formData);
    if (result) {
      Message.success('操作成功');
    }
  }
  async update(formData) {
    const result = await post('/api/inner/category/update', formData);
    if (result) {
      Message.success('操作成功');
    }
  }
  async configUpdate(formData) {
    const result = await post('/api/inner/category/config/update', formData);
    if (result) {
      Message.success('操作成功');
    }
  }
  async getList() {
    const result = await get('/api/category/list', { limit: 600 });
    if (result) {
      this.rawList = result.list;
      this.total = result.total;
      this.loading = false;
      return this.list;
    }
    return false;
  }
  findCategory(id) {
    if (!id) return '';
    const found = this.rawList.find((a) => a._id == id);
    return found ? found.title : '';
  }
}
const category = new Category();
category.getList();
export default category;
